'use client'
import React, {useCallback, useRef, useState} from "react";
import styled from "styled-components";
import {softWhite} from "@/styles/globalStyleVars";
// import Ticker from "react-ticker";
import TickerItem from "@/components/common/partner-ticker/TickerItem";
import PartnersIcon from "@/components/common/ui/icons/PartnersIcon";
import Ticker, { FinancialTicker, NewsTicker } from 'nice-react-ticker';
const PartnerTicker = (data) => {

    const state = {
        move: true,
        offset: 35,
        mode: 'chain',
    }


    return (
        <StyledNewsTicker className={'news-ticker'}>

            <div className="fixed-title">
              <PartnersIcon data={'Our Partners'}/>
            </div>


            <div className="news-ticker-wrapper">
                {/*<Ticker offset="-35"*/}
                {/*        speed={8}*/}
                {/*        direction="toLeft"  // Start from left and move to the left*/}
                {/*        move={state?.move}>*/}
                {/*    {({index}) => (*/}
                {/*        <>*/}
                {/*            <TickerItem data={data?.data?.images?.list}/>*/}
                {/*        </>*/}
                {/*    )}*/}
                {/*</Ticker>*/}

                <Ticker name={'newsWrapper'} slideSpeed={30}>
                    {
                        data?.data?.images?.list && data?.data?.images?.list?.length > 0 &&
                        data?.data?.images?.list?.map((e,index) => {
                            return(
                                <NewsTicker  id={index} meta="" change={true} url={''} title={''} icon={e?.full_path} iconAlt={'Maximus Logo'} />

                            )
                        })
                    }
                </Ticker>
            </div>
        </StyledNewsTicker>
    );
};

const StyledNewsTicker = styled.section`
    background: ${softWhite};
    position: relative;
    @media (max-width: 767px) {
        //overflow: hidden;
    }

    .fixed-title {
        position: absolute;
        top: -34px;
        left: 15px;
        z-index: 3;
    }


    .news-ticker-wrapper {
        height: 100%;
        background: ${softWhite};
        display: flex;
        flex-direction: column;
        justify-content: center;
        object-fit: cover;
        padding-bottom: 45px;
        padding-top: 45px;

        .svg {
            position: absolute;
            width: 0;
            height: 0;
        }

        .ticker__element {
            &:last-child {
                .ticker-single-item {
                    padding-right: 0 !important;
                }
            }
        }
    }


    @media (min-width: 1550px) {
        .news-ticker-wrapper {
            padding-top: 60px;
        }
    }
    @media (max-width: 767px) {
        .news-ticker-wrapper {
            padding: 25px 0 15px 0;
            height: 100px;
            display: flex;
            justify-content: center;
        }
    }
    .styles_icon__HTIz4 {
        width: auto;
        img{
            height: 60px;
            min-width: 70px;
            @media(max-width: 1536px){
                min-width: 50px;
            }
            @media(max-width: 767px){
                min-width: unset;
            }
        }
    }
    .styles_ticker-list__1JKu-{
        margin-right: 35px;
        &:last-child{
            margin-right: 0;
        }
        
        @media(min-width: 1600px){
            margin-right: 0;
        }
    }
    .styles_tickerItem__Vj57z:hover{
        background: transparent !important;
    }
    .styles_newsWrapper__KvsKt{
     display: none !important;   
    }
    .styles_ticker__-0UvZ{
     background: transparent;   
    }
    .styles_tickerItem__Vj57z{
        border: none;
        min-width: unset;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: default !important;
        width: auto !important;
    }
    .styles_tickerItem__Vj57z:after{
        display: none !important;
    }
    .styles_ticker-list__1JKu-{
        gap: 70px;
        align-items: center;
        justify-content: center;
    }
    
`;

export default React.memo(PartnerTicker);